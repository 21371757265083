export function keysToCamel(item: unknown): unknown {
    if (Array.isArray(item)) {
        return item.map((el: unknown) => keysToCamel(el));
    } else if (isStringDate(item as string)) {
        return stringAPIToDate(item as string);
    }else if (typeof item === 'function' || item !== Object(item)) {
        return item;
    }
    return Object.fromEntries(
        Object.entries(item as Record<string, unknown>).map(
            ([key, value]: [string, unknown]) => [
                key.replace(/([-_]([a-z]))/gi, c => c.toUpperCase().replace(/[-_]/g, '')).replaceAll('_', ''),
                keysToCamel(value),
            ],
        ),
    )
}


export function keysToSnake(item: any): any {
    if (typeof(item) != "object") return item;

    for(const oldName in item){

        // Camel to underscore
        const newName = oldName.replace(
            /([A-Z])/g, function($1){return "_"+$1.toLowerCase();}
        ).replace(/\d+/g, function($1){return "_"+$1;});

        // Only process if names are different
        if (newName != oldName) {
            // Check for the old property name to avoid a ReferenceError in strict mode.
            if (oldName in item) {
                item[newName] = item[oldName];
                delete item[oldName];
            }
        }

        if (item[newName] instanceof Date) {
            item[newName] = dateToFastAPIString(item[newName]);
        }

        // Recursion
        else if (typeof(item[newName]) == "object") {
            item[newName] = keysToSnake(item[newName]);
        }

    }
    return item;
}

function isObject(obj: any): boolean {
    return obj !== null && typeof obj === 'object';
}

function camelToSnake(str: string): string {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    .replace(/\d+/g, aNumber => `_${aNumber}`);
}

export function keysToSnakeV2(obj: any): any {
    if (obj instanceof Date) {
        return dateToFastAPIString(obj);
    }

    else if (Array.isArray(obj)) {
        return obj.map((item) => keysToSnakeV2(item));
    }

    else if (isObject(obj)) {
        const newObject = Object();

        Object.keys(obj).forEach((key) => {
            newObject[camelToSnake(key)] = keysToSnakeV2(obj[key]);
        })

        return newObject;
    }

    return obj;
}

/**
 * 
 * @param date date to convert to string
 * @returns return a string in the format ISO 8601
 */
export function dateToFastAPIString(date?: Date): string | undefined {
    if (!date) return undefined;

    const desplacedDate = new Date(date.getTime() - date.getTimezoneOffset()*60000);
    return desplacedDate.toISOString();
}

/**
 * 
 * @param date date to convert to string
 * @returns return a string in the format ISO 8601
 */
export function stringAPIToDate(date: string): Date {
    const removedTimezone = date.replace('Z', '').replace('+00:00', '');
    const crudeDate = new Date(removedTimezone);

    return crudeDate;
}

/**
 * Check if a string is a date. Return True if it is a date, False otherwise.
 * The date formats accepted are:
 * - 2021-09-01T00:00:00
 * - 2021-09-01T00:00:00+00:00
 * - 2021-09-01T00:00:00.000000
 * - 2021-09-01T00:00:00Z
 * - 2021-09-01T00:00:00.000000+00:00
 * - 2021-09-01T00:00:00.000000Z
 * @param date date string to check if it is a date
 * @returns 
 */
export function isStringDate(date: string): boolean {
    const allRegexValidation = [
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/,
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/,
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/,
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}\+\d{2}:\d{2}$/,
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/,

        // ISO FORMAT
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    ];

    
    return allRegexValidation.some((regex) => regex.test(date));
}

export function getRandomUUID(): string {
    try {
        return crypto.randomUUID();
    }
    catch (error) {
        console.warn("crypto.randomUUID() is not available. Using Math.random() instead.");
        const randomPart = Math.random().toString(36).substring(2, 15) + 
                           Math.random().toString(36).substring(2, 15);
        return randomPart;
    }

}
<template>
    <div class="d-flex px-4 flex-column-reverse overflow-auto" id="message-container">
        <v-scale-transition group>
            <ChatMessage
                v-for="message, index in reversedMessages"
                :key="message.id"
                :message="message"
                :id="index === reversedMessages.length - 1 ? 'lastMessage' : undefined"
            />
        </v-scale-transition>
    </div>
</template>

<script lang="ts" setup>
import ChatMessage, { Message } from '@/components/chat/ChatMessage.vue';
import { PropType, computed } from 'vue';

const props = defineProps({
    messages: {
        type: Array as PropType<Message[]>,
        required: true,
    }
});

const reversedMessages = computed(() => props.messages.slice().reverse());

</script>
import { Expose } from "class-transformer";

export class TokenSession {
    @Expose() createdAt!: Date;
    @Expose() deleted!: boolean | null;
    @Expose() device!: string;
    @Expose() expiration!: Date;
    @Expose() id!: string;
    @Expose() token!: string;
    @Expose() userId!: string;

    get isExpired() {
        return new Date() > this.expiration;
    }
}

export class User {
    @Expose() id!: string;
    @Expose() username!: string;
    @Expose() email!: string;
    @Expose() createdAt!: Date;
    @Expose() updatedAt!: Date;
    @Expose() deleted!: boolean | null;
    @Expose() isAdmin!: boolean;
}
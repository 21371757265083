<template>
    <div class="d-flex justify-center align-center container">
        <slot name="default">
            
        </slot>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 16px;
    width: 100vw;

    @media screen and (max-width: 600px) {
        padding: 8px;
    }
}
</style>
import { AuthService } from "@/services/auth_service/authService";
import { TokenSession, User } from "@/services/auth_service/models/auth";
import { ErrorResponse } from "@/services/baseService";
import { defineStore } from "pinia";
import { ref, watchEffect } from "vue";

interface AuthStoreState {
    token: string | null;
    session: TokenSession | null;
    user: any;
}

export const useAuthStore = defineStore('auth', () => {
    const authService = new AuthService();

    const token = ref<string | null>(
        localStorage.getItem('token') || null
    );

    const session = ref<TokenSession | null | undefined>();
    watchEffect(() => {
        if (session.value !== undefined) {
            token.value = session.value?.token || null;
        }
    })

    const user = ref<User | null>(null);

    watchEffect(() => {
        if (token.value) {
            localStorage.setItem('token', token.value);
            
            // Fetching user data
            authService.getProfile().then((data) => {
                if (data instanceof ErrorResponse) {
                    console.error(data);
                    return;
                }

                user.value = data;
            }).catch((error: ErrorResponse) => {
                console.error(error);
            });

            // Fetching session data
            authService.getSession().then((data) => {
                if (data instanceof ErrorResponse) {
                    console.error(data);
                    return;
                }

                session.value = data;
            }).catch((error: ErrorResponse) => {
                console.error(error);
            });
        }
        else {
            localStorage.removeItem('token');
            user.value = null
        }
    });

    return {
        token,
        session,
        user
    }
});
<template>
    <ViewContainerFullCentered>
        <canvas id="circuit-bg"></canvas>
        <v-card class="card">
            <v-card-title>
                Bienvenido a Binna
            </v-card-title>

            <v-card-text class="d-flex flex-column ga-4">
                <p>
                    Inicia sesión con tu usuario y contraseña. Si no posees uno, 
                    <RouterLink to="register">regístrate aquí</RouterLink>.
                </p>
                <v-form v-model="formIsReady" @submit.prevent class="d-flex flex-column">
                    <CTextField
                        v-model="username"
                        label="Nombre de usuario"
                        name="username"
                        type="username"
                        :rules="[(value: string) => !!value]"
                    />
                    <CTextField
                        v-model="password"
                        label="Contraseña"
                        name="password"
                        type="password"
                        autocomplete="current-password"
                        :rules="[(value: string) => !!value]"
                    />
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            type="submit"
                            :loading="sendingLoginRequest"
                            value="login"
                            @click="sendLoginRequest"
                        >
                            Entrar
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </ViewContainerFullCentered>
</template>

<script setup lang="ts">
import ViewContainerFullCentered from '@/components/general/templates/ViewContainerFullCentered.vue';
import CTextField from '@/components/general/atoms/CTextField.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { AuthService } from '@/services/auth_service/authService';
import { Notification } from '@/stores/notificationStore';
import { ErrorResponse } from '@/services/baseService';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from 'vue-router';

const username = ref("")
const password = ref("")
const formIsReady = ref(false)
const authStore = useAuthStore();
const router = useRouter();

interface Node {
    x: number;
    y: number;
    size: number;
    dx: number;
    dy: number;
    color: string;
}

const authService = new AuthService();
const sendingLoginRequest = ref(false);
async function sendLoginRequest() {
    sendingLoginRequest.value = true;
    const response = await authService.login(username.value, password.value);

    if (response instanceof ErrorResponse) {
        if (response.statusCode === 401) {
            new Notification({
                title: "Error",
                message: "Usuario o contraseña incorrectos",
                type: "error",
                timeout: 5000
            });
        } else if (!response.notified) {
            new Notification({
                title: "Error",
                message: response.message,
                type: "error",
                timeout: 5000
            });
        }
    }
    else {
        authStore.session = response;
        new Notification({
            title: "Bienvenido nuevamente",
            type: "success",
            timeout: 3000
        })
        router.push({ name: "home" });
    }

    sendingLoginRequest.value = false;
}

function animateCircuits() {
    const circuitCanvas = document.getElementById('circuit-bg') as HTMLCanvasElement;
    if (!circuitCanvas) return;

    const context = circuitCanvas.getContext('2d') as CanvasRenderingContext2D;
    const circuits: Node[] = [];
    const colors = ['#EEA900'];

    circuitCanvas.width = window.innerWidth;
    circuitCanvas.height = window.innerHeight;

    window.addEventListener('resize', () => {
        circuitCanvas.width = window.innerWidth;
        circuitCanvas.height = window.innerHeight;
    });

    function createCircuit() {
        circuits.push({
            x: Math.random() * circuitCanvas.width,
            y: Math.random() * circuitCanvas.height,
            size: Math.random() * 1.5 + 0.5,
            dx: (Math.random() - 0.5) * 1,
            dy: (Math.random() - 0.5) * 1,
            color: colors[Math.floor(Math.random() * colors.length)]
        });
    }

    for (let i = 0; i < 30; i++) {
        createCircuit();
    }

    function animate() {
        context.clearRect(0, 0, circuitCanvas.width, circuitCanvas.height);
        circuits.forEach((circuit, index) => {
            context.beginPath();
            context.arc(circuit.x, circuit.y, circuit.size, 0, Math.PI * 2, false);
            context.fillStyle = circuit.color;
            context.fill();

            for (let j = index + 1; j < circuits.length; j++) {
                const dx = circuit.x - circuits[j].x;
                const dy = circuit.y - circuits[j].y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < 100) { // Ajustar esta distancia según tu preferencia
                    context.beginPath();
                    context.moveTo(circuit.x, circuit.y);
                    context.lineTo(circuits[j].x, circuits[j].y);
                    context.strokeStyle = `rgba(255, 193, 7, ${(1 - distance / 150)/8})`;
                    context.stroke();
                }
            }

            circuit.x += circuit.dx;
            circuit.y += circuit.dy;

            if (circuit.x < 0 || circuit.x > circuitCanvas.width) {
                circuit.dx *= -1;
            }
            if (circuit.y < 0 || circuit.y > circuitCanvas.height) {
                circuit.dy *= -1;
            }
        });

        requestAnimationFrame(animate);
    }

    animate();
}

function changeThemeColor(color: string) {
    let metaThemeColor = document.querySelector('meta[name="theme-color"][media="(prefers-color-scheme: dark)"]');
    if (metaThemeColor) {
        metaThemeColor.setAttribute("content", color);
    } else {
        const metaThemeColor = document.createElement('meta');
        metaThemeColor.name = "theme-color";
        metaThemeColor.content = color;
        document.head.appendChild(metaThemeColor);
    }
}

onMounted(() => {
    changeThemeColor("#121212")
    animateCircuits();
});

onUnmounted(() => {
    changeThemeColor("#000000")
});

</script>

<style lang="scss" scoped>
.card {
    max-width: 400px;
}

#circuit-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

</style>
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import HomeView from '@/views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        component: () => import('@/views/CustomerView.vue'),
        name: 'customers',
        path: 'customers'
      },
      {
        component: () => import('@/views/CalendarView.vue'),
        name: 'calendar',
        path: 'calendar'
      },
      {
        component: () => import('@/views/SettingView.vue'),
        name: 'settings',
        path: 'settings'
      },
      {
        component: () => import('@/views/OpportunityView.vue'),
        name: 'opportunities',
        path: 'opportunities'
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

export default router

<template>
    <div class="d-flex ga-2" :class="flexDirectionClass">
        <div>
            <div class="d-flex align center ga-2" :class="flexDirectionClass">
                <v-icon
                    size="small"
                    :icon="prependIcon || appendIcon"
                >
                </v-icon>
                <span>
                    {{ autorLabel }}
                </span>
            </div>
            <v-card
                :class="textDirectionClass"
                class="rounded-lg message-card"
                elevation="0"
                :rounded="message.fromBinna ? 'ts-0' : 'te-0'"
            >
                <v-card-text>
                    <div class="d-flex flex-column ga-2 align-start">
                        <vue-markdown v-if="!message.fromBinna || !!message.text" :source="parsedMessage.text"/>
                        <CLoader v-else/>
                        <ActionResultChip
                            v-for="(item, index) in parsedMessage.json"
                            :key="index"
                            class="flex-0-0"
                            :success="JSON.parse(item['output'])['success']"
                            :label="JSON.parse(item['output'])['function_name']"
                            :description="JSON.parse(item['output'])['message']"
                            :detailList="JSON.parse(item['output'])['detail_list']"
                            size="x-small"
                        />
                    </div>
                </v-card-text>
            </v-card>
            <p class="text-caption text-disabled font-weight-light" :class="textDirectionClass">
                {{ message.createdAt.toLocaleTimeString() }}
            </p>
        </div>
        <v-spacer class="w-25"></v-spacer>
    </div>
</template>

<script lang="ts">

export interface Message {
    id: number;
    text: string;
    fromBinna: boolean;
    createdAt: Date;
}

</script>

<script lang="ts" setup>
import { PropType, computed } from 'vue';
import CLoader from '@/components/general/atoms/CLoader.vue';
import ActionResultChip from '@/components/chat/ActionResultChip.vue';
import VueMarkdown from 'vue-markdown-render';

const props = defineProps({
    message: {
        type: Object as PropType<Message>,
        required: true
    },
});

const parsedMessage = computed(() => {
    const [text, json] = props.message.text.split('\\json');
    return {
        text: text,
        json: json ? JSON.parse(json) : []
    };
})

const textDirectionClass = computed(() => {
    return props.message.fromBinna ? 'text-left' : 'text-right';
})

const prependIcon = computed(() => {
    return props.message.fromBinna ? 'mdi-robot' : undefined;
})

const appendIcon = computed(() => {
    return props.message.fromBinna ? undefined : 'mdi-account';
})

const flexDirectionClass = computed(() => {
    return props.message.fromBinna ? 'flex-row' : 'flex-row-reverse';
})

const autorLabel = computed(() => {
    return props.message.fromBinna ? 'Binna' : 'Tú';
})

</script>

<style scoped lang="scss">
:deep(ol), :deep(ul) {
    margin-inline-start: 16px;
    padding-right: 4px;

    li::marker {
        color: #EEA900;
        font-weight: bold;
    }
}

.message-card {
    background-color: #0D0D0D;
}

</style>
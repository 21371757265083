<template>
    <v-sheet class="pa-4 pb-6 d-flex align-center">
        <div class="chat-input-container flex-item">
            <CTextField
                placeholder="Escribe un mensaje"
                variant="outlined"
                hideDetails
                rounded
                type="textarea"
                @keydown.enter.prevent="handleKeyDown"
                v-model="message"
                :rows="1"
            />
        </div>

        <div class="flex-item button-container pl-2" :class="buttonContainerComputedClass">
            <v-btn
                class="button"
                icon="mdi-microphone"
                color="primary"
                size="large"
                density="compact"
            />
        </div>
    </v-sheet>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import CTextField from '@/components/general/atoms/CTextField.vue';

const message = ref('')
const emit = defineEmits<{
    (event: 'sendMessage', message: string): void
    (event: 'createThread'): void
}>()

function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
        if (event.metaKey || event.shiftKey || event.altKey) {
            message.value += '\n'
            return
        }
        sendMessage()
    }
}

function sendMessage() {
    if (message.value.trim() === '') return
    emit('sendMessage', message.value)
    message.value = ''
}

const buttonContainerComputedClass = computed(() => {
    return message.value.trim() === '' ? 'visible-element' : 'hidden-element'
})
</script>

<style scoped lang="scss">

.chat-input-container {
    width: 100%;
    flex-basis: 100%;
}

.flex-item {
    overflow: hidden;
}

.hidden-element {
    overflow: .00001;

    -webkit-animation: flexShrink 500ms ease forwards;
    -o-animation: flexShrink 500ms ease forwards;
    animation: flexShrink 500ms ease forwards;
}

.button-container {
    max-width: 52px;
    flex-basis: 52px;

    .button {
        width: 36px;
        min-width: 36px;
    }
}

.visible-element {
    flex: .000001;

    -webkit-animation: flexGrow 500ms ease forwards;
    -o-animation: flexGrow 500ms ease forwards;
    animation: flexGrow 500ms ease forwards;
}

@keyframes flexShrink {
    to {
        flex-basis: 0;
        opacity: 0;
        scale: 0.7;
    }
}

@-webkit-keyframes flexShrink {
    to {
        flex-basis: 0;
        opacity: 0;
        scale: 0.7;
    }
}

@-o-keyframes flexShrink {
    to {
        flex-basis: 0;
        opacity: 0;
        scale: 0.7;
    }
}

@-webkit-keyframes flexGrow {
    to {
        flex-basis: 52px;
        opacity: 1;
        scale: 1;
    }
}

@-o-keyframes flexGrow {
    to {
        flex-basis: 52px;
        opacity: 1;
        scale: 1;
    }
}

@keyframes flexGrow {
    to {
        flex-basis: 52px;
        opacity: 1;
        scale: 1;
    }
}


</style>
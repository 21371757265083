import { BaseService, ErrorResponse, ResponseErrorCode } from "../baseService";
import { keysToCamel } from "../utils"; 

export class ChatService extends BaseService {
    
    async* sendMessage(message: string, threadId: string) {
        const url = `${this.URL}/chat/send`;
        const data = {
            "content": message,
            "thread_id": threadId,
        };

        let response;
        try {
            response = await fetch(url, {
                method: 'POST',
                headers: this.getHeaders(false),
                body: JSON.stringify(data),
            })
        } catch (error) {
            console.log("Detalle del error:", error);
            yield new ErrorResponse(
                'Error interno',
                500,
                false,
                ResponseErrorCode.INTERNAL_ERROR
            );
            return;
        }

        if (!response.ok) {
            const data = await response.json();
            console.log("Detalle del error utilizando el OK:", data);
            yield new ErrorResponse(
                data["detail"]["detail"],
                response.status,
                false,
                data["detail"]["error_code"]
            )
            return;
        }

        if (response.body === null) {
            throw new Error('Response body is empty')
        }

        const reader = response.body.getReader();
        const isDone = false;
        const decoder = new TextDecoder();
        while (!isDone) {
            const { done, value } = await reader.read();
            const text = decoder.decode(value);
            if (done) {
                console.log('Stream finished');
                break;
            }
            yield text;
        }

        return;
    }

    async retrieveMessages(threadId: string): Promise<OpenAIMessage[]> {
        const url = `${this.URL}/chat/retrieve?thread_id=${threadId}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: this.getHeaders(false),
        })

        if (!response.ok) {
            throw new Error('Failed to retrieve messages')
        }

        const data = await response.json();
        const parsedData = keysToCamel(data.data) as OpenAIMessage[];

        return parsedData.reverse();
    }

    async createThread() {
        const url = `${this.URL}/chat/create`;

        const response = await fetch(url, {
            method: 'POST',
            headers: this.getHeaders(false),
        })

        if (!response.ok) {
            throw new Error('Failed to create thread')
        }

        const data = await response.json();

        return data['thread_id'];
    }
}
import { getRandomUUID } from '@/services/utils';
import { defineStore } from 'pinia';

export class Notification {
    id: string;
    message?: string;
    type: 'info' | 'error' | 'warning' | 'success';
    timeout: number;
    notificationStore: any;
    timeoutAlarmId: number;
    createdAt = new Date();
    title?: string;

    constructor({
        title,
        message,
        type,
        timeout = 3000,
    }: {
        title?: string;
        message?: string;
        type: 'info' | 'error' | 'warning' | 'success';
        timeout?: number;
    }) {
        // Note: Crypto is only available in HTTPS or localhost
        this.id = getRandomUUID();
        this.title = title;
        this.message = message;
        this.type = type;
        this.timeout = timeout;
        this.notificationStore = useNotificationStore();

        this.notificationStore.addNotification(this);

        this.timeoutAlarmId = setTimeout(() => {
            this.close();
        }, this.timeout);
    }

    close() {
        this.notificationStore.removeNotification(this.id);
        clearTimeout(this.timeoutAlarmId);
    }
}

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        notifications: [],
    } as {
        notifications: Notification[];
    }),
    
    actions: {
        addNotification(notification: Notification) {
            this.notifications.push(notification);
        },
        
        removeNotification(id: string) {
            this.notifications = this.notifications.filter((n) => n.id !== id);
        },
    },
});
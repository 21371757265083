<template>
    <div class="d-flex flex-column justify-end overflow-hidden ga-2">
        <ChatMessageContainer
            class="flex-1"
            :messages="messages"
        />
        <ChatInputContainer
            class="flex-0-0"
            ref="chatInput"
            @create-thread="() => createThread()"
            @send-message="message => sendMessage(message)"
        />
    </div>
</template>

<script lang="ts" setup>
import ChatMessageContainer from '@/components/chat/ChatMessageContainer.vue';
import ChatInputContainer from '@/components/chat/ChatInputContainer.vue';
import { Message } from '@/components/chat/ChatMessage.vue';
import { onMounted, ref } from 'vue';
import { ChatService } from '@/services/chat_service/chatService';
import { ErrorResponse, ResponseErrorCode } from '@/services/baseService';

const chatService = new ChatService();
const binnaStreamingMessage = ref<Message>();

const messages = ref<Message[]>([]);
const threadId = ref<string>();

function addMessage(message: string, fromBinna = false) {
    messages.value.push({
        id: messages.value.length + 1,
        text: message,
        fromBinna: fromBinna,
        createdAt: new Date()
    });
}

async function sendMessage(message: string) {
    if (!threadId.value) {
        await createThread()
        if (!threadId.value) {
            console.error('Thread not created');
            return;
        }
    }
    addMessage(message);
    
    const response = chatService.sendMessage(
        message,
        threadId.value
    );

    binnaStreamingMessage.value = {
        id: messages.value.length + 1,
        text: '',
        fromBinna: true,
        createdAt: new Date()
    }

    messages.value.push(binnaStreamingMessage.value);

    for await (const streamingDelta of response) {
        if (streamingDelta instanceof ErrorResponse) {
            if (streamingDelta.errorCode === ResponseErrorCode.NOT_CURRENT_USAGE_LIMIT_EXCEPTION) {
                binnaStreamingMessage.value.text = "Lo siento, no cuenta con una membresía activa. Contacta a soporte";
                return;
            }
            else if (streamingDelta.errorCode === ResponseErrorCode.USER_TOKEN_LIMIT_IS_REACHED) {
                binnaStreamingMessage.value.text = "Lo siento, ha alcanzado el limite de mensajes de su plan. Contacta a soporte";
                return;
            }

            binnaStreamingMessage.value.text = "Lo siento, no puedo generar una respuesta en este momento. Contacta a soporte";
            return;
        }
        binnaStreamingMessage.value.text += streamingDelta;
    }
}

async function retrieveMessages() {
    if (!threadId.value) {
        return;
    }

    const response = await chatService.retrieveMessages(threadId.value);
    messages.value = response.map((message, index) => ({
        id: index + 1,
        text: message.content[0].text.value,
        fromBinna: message.role == "assistant",
        createdAt: new Date(message.createdAt)
    }));
}

async function createThread() {
    const response = await chatService.createThread();
    threadId.value = response as string;

    localStorage.setItem('threadId', threadId.value);
    messages.value = [];
}

onMounted(() => {
    const savedThreadId = localStorage.getItem('threadId');
    if (savedThreadId) {
        threadId.value = savedThreadId;
        retrieveMessages();
    }
})

</script>

<style scoped>

.flex-1 {
    flex: 1;
}

.flex-0-0 {
    flex: 0 0;
}

</style>
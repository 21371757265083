<template>
    <div>
        <CLabel v-if="label" :label="label" :required="required"/>
        <v-text-field
            v-if="!isTextArea"
            :rounded="rounded ? 'xl' : undefined"
            :model-value="modelValue"
            @update:model-value="$emit('update:modelValue', $event)"
            :variant="variant"
            density="compact"
            :type="type"
            :placeholder="placeholder"
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :append-inner-icon="appendInnerIcon"
            :width="width"
            :rules="(rules as any)"
            :prefix="prefix"
            single-line
            active
            :hideDetails="hideDetails"
            :name="name || label"
            :autocomplete="autocomplete"
        ></v-text-field>

        <v-textarea
            v-else
            :rounded="rounded ? 'xl' : undefined"
            :model-value="modelValue"
            @update:model-value="$emit('update:modelValue', $event)"
            :variant="variant"
            density="compact"
            :placeholder="placeholder"
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :append-inner-icon="appendInnerIcon"
            :width="width"
            :rules="(rules as any)"
            no-resize
            :rows="rows"
            :hideDetails="hideDetails"
            :maxRows="maxRows"
            auto-grow
            :name="name || label"
            :autocomplete="autocomplete"
        ></v-textarea>
    </div>
</template>

<script lang="ts">

export const ValidationRules = {
    required: (value?: string) => !!value || 'Campo requerido',
    email: (value?: string) => (!value || value === '' || /.+@.+\..+/.test(value)) || 'Correo inválido',
    phone: (value?: string) => (!value || value === '' || /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value)) || 'Teléfono inválido (ej: +56912345678)',
    maxLength: (max: number) => (value?: string) => (value || '').length <= max || `Máximo ${max} caracteres`,
    minLength: (min: number) => (value?: string) => (value || '').length >= min || `Mínimo ${min} caracteres`
}

export type VariantType = "underlined" | "filled" | "outlined" | "plain" | "solo" | "solo-inverted" | "solo-filled"

</script>

<script lang="ts" setup>
import { PropType, computed } from 'vue';
import CLabel from '@/components/general/atoms/CLabel.vue';

const props = defineProps({
    label: {
        type: String,
        required: false
    },
    modelValue: {
        type: String,
        required: false
    },
    type: {
        type: String,
        required: false,
        default: 'text'
    },
    rules: {
        type: Array,
        required: false
    },
    placeholder: {
        type: String,
        required: false
    },
    prependIcon: {
        type: String,
        required: false
    },
    appendInnerIcon: {
        type: String,
        required: false
    },
    appendIcon: {
        type: String,
        required: false
    },
    width: {
        type: String,
        required: false
    },
    prefix: {
        type: String,
        required: false
    },
    hideDetails: {
        type: Boolean,
        required: false,
        default: false
    },
    rows: {
        type: Number,
        required: false,
        default: 2
    },
    maxRows: {
        type: Number,
        required: false,
        default: 3
    },
    variant: {
        type: String as PropType<VariantType>,
        required: false,
        default: 'outlined'
    },
    name: {
        type: String,
        required: false,
        default: undefined
    },
    autocomplete: {
        type: String,
        required: false,
        default: undefined
    },
    rounded: {
        type: Boolean,
        required: false,
        default: false
    }
});

const isTextArea = computed(() => props.type === 'textarea');

const required = computed(() => {
    return props.rules?.some((rule: any) => rule() === 'Campo requerido')
})

</script>
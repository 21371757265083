<template>
    <div class="app-container overflow-hidden">
        <!-- Navigation panel (Like WatchOS) -->
        <div class="complication-containers">
            
            <!-- Small Complications -->
            <div class="d-flex flex-column ga-2">
                <div
                    class="small-complication-container round bg-surface rounded-xl overflow-hidden logo-binna-container"
                    :class="computedBinnaLogoContainerClass"
                    @click="toggleMenu"
                >
                    <v-img
                        src="img/logo-binna.svg"
                        width="48px"
                        height="48px"
                    />
                </div>

                <TransitionGroup name="list">
                    <div v-for="element in sortedMenuElements" :key="element.id" >
                        <v-btn
                            v-if="element.type === 'button' && (element.visible === undefined || (typeof element.visible === 'function' ? element.visible() : element.visible))"
                            class="small-complication-container menu-button"
                            :ripple="element.ripple"
                            :variant="element.variant"
                            @click="element.action"
                            :icon="typeof element.icon === 'function' ? element.icon() : element.icon"
                            :class="activeRouteName === element.routeName ? 'active' : ''"
                        />
                        <v-divider
                            v-else-if="element.type === 'divider' && (element.visible === undefined || (typeof element.visible === 'function' ? element.visible() : element.visible))"
                        />
                    </div>
                </TransitionGroup>
            </div>

            <!-- Big Complications -->
            <div class="flex-1">
                <div class="big-complication-container bg-surface flex-1 rounded-lg pa-2 d-flex ga-2 align-center">
                    <div class="event-container">
                        <span class="text-bold mr-2">12:00 - 18:00</span>
                        <span class="text-light">Beta Binna One</span>
                    </div>
                </div>
            </div>

        </div>

        <Transition name="scroll">
            <div v-if="fullScreenMenu" class="px-2" :key="router.currentRoute.value.name">
                <RouterView/>
            </div>
        </Transition>

        <!-- Chat Panel -->
        <v-sheet
            class="chat-container"
            :class="computedChatContainerClass"
        >
            <div class="bar-container">
                <div class="horizontal-bar">
                </div>
            </div>
            <ChatWithBinna class="flex-1"/>

        </v-sheet>
    </div>
</template>

<script lang="ts" setup>
type MenuElementType = 'button' | 'divider'

interface MenuElement {
    type: MenuElementType;
    visible?: boolean | (() => boolean);
    id: number;
}

interface MenuButton extends MenuElement {
    type: 'button';
    icon: string | (() => string);
    variant?: 'text' | 'outlined' | 'plain';
    ripple?: boolean;
    action?: () => void;
    routeName?: string;
}

interface MenuDivider extends MenuElement {
    type: 'divider';
}

type MenuElementSubtypes = MenuButton | MenuDivider;

import ChatWithBinna from '@/components/chat/ChatWithBinna.vue';
import { AuthService } from '@/services/auth_service/authService';
import { ErrorResponse } from '@/services/baseService';
import { Notification } from '@/stores/notificationStore';
import { useAuthStore } from '@/stores/authStore';
import { computed, onMounted, ref, watchEffect, TransitionGroup } from 'vue';
import { useRouter, RouterView } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();
const authService = new AuthService();

const menuElements: MenuElementSubtypes[] = [
    { id: 1, type: 'button', icon: 'mdi-calendar', ripple: false, action: () => goTo('calendar'), routeName: 'calendar' },
    { id: 2, type: 'button', icon: 'mdi-receipt-text-check-outline', ripple: false, action: () => goTo('opportunities'), routeName: 'opportunities' },
    { id: 3, type: 'button', icon: () => menuIsTotallyOpen.value ? 'mdi-chevron-up' : 'mdi-chevron-down', variant: 'text', ripple: false, action: toggleTotallyMenu },
    { id: 4, type: 'divider' },
    { id: 5, type: 'button', icon: 'mdi-account', ripple: false, action: () => goTo('customers'), routeName: 'customers' },
    { id: 6, type: 'button', icon: 'mdi-cog', ripple: false, action: () => goTo('settings'), routeName: 'settings' },
    { id: 7, type: 'button', icon: 'mdi-logout', variant: 'plain', ripple: true, action: () => logout() },
]
enum MenuState {
    CLOSED = 'closed',
    OPEN = 'open',
    TOTALLY_OPEN = 'totally-open',
}
const menuState = ref<MenuState>(MenuState.CLOSED);
const fullScreenMenu = ref(false);
const menuIsOpen = computed(() => {
    return menuState.value !== MenuState.CLOSED;
})
const menuIsTotallyOpen = computed(() => {
    return menuState.value === MenuState.TOTALLY_OPEN;
})
const activeRouteName = ref<string | null>(null);

const sortedMenuElements = ref<MenuElementSubtypes[]>([...menuElements]);

watchEffect(() => {
    sortedMenuElements.value = [...menuElements];
    if (activeRouteName.value !== null) {
        // Active element is the one that has the same routeName as the activeRouteName
        const activeElement = menuElements.find(element => 'routeName' in element && element.routeName === activeRouteName.value);
        console.log('Active route name', activeRouteName.value, activeElement);
        if (activeElement) {
            const positionalIndexMap = {
                [activeElement.id]: 0,
                3: 1,
                4: 2,
                ...menuElements.filter(element => ![activeElement.id, 3, 4].includes(element.id)).reduce((acc, element, index) => {
                    acc[element.id] = index + 3;
                    return acc;
                }, {} as Record<number, number>)
            }
            sortedMenuElements.value.sort((a, b) => {
                return positionalIndexMap[a.id] < positionalIndexMap[b.id] ? -1 : 1;
            });
        } else {
            sortedMenuElements.value = menuElements;
        }
    } else {
        sortedMenuElements.value.sort((a, b) => a.id < b.id ? -1 : 1);
    }

    if (menuState.value === MenuState.CLOSED) {
        sortedMenuElements.value = [];
    }

    if (menuState.value === MenuState.OPEN) {
        const dividerIndex = sortedMenuElements.value.findIndex(element => element.type === 'divider');
        if (dividerIndex !== -1) {
            sortedMenuElements.value = sortedMenuElements.value.slice(0, dividerIndex);
        }
    }
});

const binnaChatTopMargin = computed(() => {
    if (fullScreenMenu.value) return '100vh';
    else if (menuState.value === MenuState.TOTALLY_OPEN) return '420px';
    else if (menuState.value === MenuState.OPEN) {
        return activeRouteName.value ? '180px' : '240px';
    }
    else return '78px';
})

function toggleMenu() {
    if (menuState.value !== MenuState.CLOSED) {
        menuState.value = MenuState.CLOSED;
        activeRouteName.value = null;
        fullScreenMenu.value = false;
    } else {
        menuState.value = MenuState.OPEN;
    }
}

function toggleTotallyMenu() {
    if (menuState.value !== MenuState.TOTALLY_OPEN) {
        menuState.value = MenuState.TOTALLY_OPEN;
    } else {
        menuState.value = MenuState.OPEN;
    }
}

function goTo(routeName: string) {
    activeRouteName.value = routeName;
    fullScreenMenu.value = true;
    menuState.value = MenuState.OPEN;

    try{
        router.push({ name: routeName });
    } catch (e) {
        console.error('Error navigating to route', routeName, e);
        router.push({ name: 'home' });
    }
}

const computedChatContainerClass = computed(() => {
    if (menuState.value === MenuState.TOTALLY_OPEN) return 'chat-container-totally-closed';
    else if (menuState.value === MenuState.CLOSED) return 'chat-container-open';
    else return 'chat-container-closed';
})

const computedBinnaLogoContainerClass = computed(() => {
    const isActive = menuState.value !== MenuState.CLOSED;

    return isActive ? 'active' : '';
})

const sendingLogoutRequest = ref(false)
async function logout() {
    sendingLogoutRequest.value = true;
    const response = await authService.logout();

    if (response instanceof ErrorResponse) {
        new Notification({
            title: "Error",
            message: 'Error al cerrar sesión',
            type: "error",
            timeout: 5000
        });
    }
    authStore.token = null;
    router.push({ name: 'login' });

    sendingLogoutRequest.value = false;
}

onMounted(() => {
    if (!authStore.token) {
        authStore.token = null;
        router.push({ name: 'login' });
    }
});

</script>

<style lang="scss" scoped>

.chat-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: v-bind(binnaChatTopMargin);

    overflow: hidden;

    display: flex;
    flex-direction: column  ;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    transition: top 0.3s;

    .bar-container{
        display: flex;
        justify-content: center;
        padding: 8px;

        .horizontal-bar {
            height: 4px;
            background-color: #545454;
            width: 200px;
            border-radius: 4px;
        }
    }
}

.complication-containers {
    flex-wrap: nowrap;
    padding: 16px;
    display: flex;
    gap: 14px;
    font-size: 14px;

    .big-complication-container {
        text-wrap: nowrap;
        overflow: hidden;
        height: 48px;

        .event-container {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-left: 4px solid #EEA900;
            padding-left: 4px;
        }
    }

    .small-complication-container {
        width: 48px;
        height: 48px;
    }

    .menu-button {
        transition: none;
    }
    .menu-button:active, .menu-button.active {
        background-color: #EEA900;
        color: #000000;
    }

    .exit-menu-button {
        transition: none;
    }
    .exit-menu-button:active {
        color: #EE0202;
    }
}

.logo-binna-container {
    transition: all 0.3s;
    border: 2px solid #000000;
    
    &.active {
        box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.2);
        border-color: #ffffff;
    }
}

.flex-1 {
    flex: 1;
}

.text-light {
    color: #B0B0B0;
}

.text-bold {
    font-weight: bold;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.4s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}


.animation-element, .scroll-enter-active, .scroll-leave-active {
  transition: all 0.6s ease-in-out;
  position: absolute;
  width: 100%;
}
.scroll-enter-from /* .scroll-y-transition-leave-active in <2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
  scale: 1.4;
}

.scroll-leave-to /* .scroll-y-transition-enter-active in <2.1.8 */ {
  transform: translateX(-50px);
  opacity: 0;
  scale: 0.5;
}

</style>
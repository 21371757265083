import { BaseService, ErrorResponse } from "../baseService";
import { TokenSession, User } from "./models/auth";

export class AuthService extends BaseService {

    async login(username: string, password: string): Promise<TokenSession | ErrorResponse> {
        const slug = 'auth/login';

        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        return await this.post(slug, {
            body: formData,
            useFormData: true,
            parseClass: TokenSession
        });
    }

    async logout(): Promise<ErrorResponse> {
        const slug = 'auth/logout';

        return await this.post(slug);
    }

    async getProfile(): Promise<User | ErrorResponse> {
        const slug = 'auth/me';

        return await this.get(slug);
    }

    async getSession(): Promise<TokenSession | ErrorResponse> {
        const slug = 'auth/session';

        return await this.get(slug);
    }
}
import { Notification } from "@/stores/notificationStore";
import { keysToCamel, keysToSnakeV2 } from "./utils";
import { ClassConstructor, plainToClass } from "class-transformer";
import { PostBodyParams } from "./types";

export enum ResponseErrorCode {
    INTERNAL_ERROR = 'InternalError',
    USER_TOKEN_LIMIT_IS_REACHED = 'UserTokenLimitIsReachedException',
    NOT_CURRENT_USAGE_LIMIT_EXCEPTION = 'NotCurrentUsageLimitException',
    UNAUTHORIZED_ERROR = 'UnauthorizedError'
}

export class ErrorResponse {
    message: string;

    /**
     * HTTP Response status code
     */
    statusCode: number;

    /**
     * Error response code. Like: "TokenLimitReached"
     */
    errorCode?: ResponseErrorCode;

    /**
     * If the error has been notified to the user.
     * Useful to prevent multiple notifications for the same error.
     */
    notified: boolean;

    constructor(message: string, statusCode: number, notified = false, errorCode?: ResponseErrorCode) {
        this.message = message;
        this.statusCode = statusCode;
        this.notified = notified;
        this.errorCode = errorCode
    }
}

export class BaseService {

    //URL = 'http://192.168.1.129:8000';
    //URL = 'http://localhost:8000';
    URL = 'https://api.binna.app'

    protected async get<T>(url: string) {
        url = this.cleanUrl(url);

        const headers = this.getHeaders(false);

        try {
            const response = await fetch(this.URL + url, {
                headers: headers
            });

            return await this.handleResponse<T>(response);
        }
        catch(e: any){
            return this.handleUnexpectedError(e);
        }
    }

    protected async post<T>(
        url: string,
        params?: PostBodyParams<T>
    ): Promise<ErrorResponse | T> {
        url = this.cleanUrl(url);

        const headers = this.getHeaders(params?.useFormData || false);
        const parsedBody = this.parseBody(
            params?.body,
            params?.useFormData || false
        );

        try{
            const response = await fetch(this.URL + url, {
                method: 'POST',
                body: parsedBody,
                headers: headers
            });

            return await this.handleResponse<T>(
                response,
                params?.parseClass
            );
        }
        catch(e: any){
            return this.handleUnexpectedError(e);
        }

        
    }


    protected async put(url: string, data: any, useFormData = false) {
        url = this.cleanUrl(url);

        const headers = this.getHeaders(useFormData);
        const parsedBody = this.parseBody(data, useFormData);

        const response = await fetch(this.URL + url, {
            method: 'PUT',
            body: parsedBody,
            headers: headers
        });

        const responseData = await response.json();
        return keysToCamel(responseData) as any;
    }

    protected async delete(url: string) {
        url = this.cleanUrl(url);
        const response = await fetch(this.URL + url, {
            method: 'DELETE'
        });
        const responseData = await response.json();
        return keysToCamel(responseData) as any;
    }

    private cleanUrl(url: string) {
        if (url.startsWith('/')) {
            return url;
        }
        return '/' + url;
    }

    private parseBody(data: any, useFormData: boolean) {
        if (useFormData) {
            return data;
        }
        return JSON.stringify(keysToSnakeV2(data));
    }

    protected getHeaders(useFormData: boolean) {
        if (useFormData) {
            return undefined
        }
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        const token = localStorage.getItem('token');
        if(token){
            headers['Authorization'] = `Bearer ${token}`;
        }
        return headers;
    }

    private handleUnexpectedError(error: any) {
        console.error(error);
        new Notification({
            title: 'Error inesperado',
            message: 'Al parecer hubo un error en el servidor, por favor intenta más tarde',
            type: 'error',
            timeout: 3000
        })
        return new ErrorResponse('Error inesperado', 500, true, ResponseErrorCode.INTERNAL_ERROR);
    }

    private async handleErrorResponse(response: Response): Promise<ErrorResponse | null> {
        if (!response.ok) {

            if(response.status === 401){
                console.log('Sesión expirada');
                new Notification({
                    title: 'Sesión expirada',
                    message: 'Tu sesión ha expirado, por favor vuelve a iniciar sesión',
                    type: 'error',
                    timeout: 3000
                })
                localStorage.removeItem('token');
                window.location.href = '/login';
                return new ErrorResponse(
                    'Sesión expirada',
                    401,
                    false,
                    ResponseErrorCode.UNAUTHORIZED_ERROR
                );
            }

            const responseData = await response.json();
            return new ErrorResponse(
                responseData.detail,
                response.status,
                false,                      // TODO: get notified param from response
                responseData['error_code']
            );
        }
        return null;
    }

    private async handleResponse<T>(response: Response, parseClass?: ClassConstructor<T>): Promise<ErrorResponse | T> {
        const errorResponse = await this.handleErrorResponse(response);
        if(errorResponse) return errorResponse;

        const responseData = await response.json();
        const camelizedResponse = keysToCamel(responseData) as T;

        if(parseClass){
            return plainToClass(parseClass, camelizedResponse);
        }

        return camelizedResponse;
    }
}

export class CRUDBaseService<BaseModel, ResponseModel> extends BaseService {

    constructor(private prefixUrl: string) {
        super();
    }

    get slug() {
        return this.prefixUrl
    }

    public async getOne(id: number): Promise<ResponseModel | ErrorResponse> {
        return this.get(`/${this.slug}/${id}`);
    }

    public async getAll(): Promise<ResponseModel[] | ErrorResponse> {
        return this.get(`/${this.slug}/`);
    }

    public async createModel(data: BaseModel) {
        return this.post(`/${this.slug}/`, {
            body: data
        });
    }

    public async updateModel(id: number, data: ResponseModel) {
        return this.put(`/${this.slug}/${id}`, data);
    }

    public async deleteModel(id: number) {
        return this.delete(`/${this.slug}/${id}`);
    }
}

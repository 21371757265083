import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// Pinia
import { createPinia } from 'pinia'
const pinia = createPinia()


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as vuetifyComponents from 'vuetify/components'
import * as vuetifyDirectives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.css"
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { VDateInput } from 'vuetify/lib/labs/components.mjs'
import { es } from 'vuetify/locale'

const binnaDarkTheme = {
    dark: true,
    colors: {
        primary: '#EEA900',
        secondary: '#FFA000',
        accent: '#EEA900',
        error: '#EE0202',
        info: '#525252',
        success: '#A7C04B',
        warning: '#995252',

        background: '#000000',
        surface: '#212121',
    }
}

const binnaLightTheme = {
    dark: false,
    colors: {
        primary: '#EEA900',
        secondary: '#FFA000',
        accent: '#EEA900',
        error: '#FF5252',
        info: '#525252',
        success: '#A7C04B',
        warning: '#995252',

        background: '#F3F3F3',
        surface: '#FFFFFF',
    }
}


import DateFnsAdapter from "@date-io/date-fns"
import { es as esDateFns } from 'date-fns/locale'

const vuetify = createVuetify({
    components: {
        ...vuetifyComponents,
        VDateInput
    },
    directives: vuetifyDirectives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        defaultTheme: 'dark',
        themes: {
            dark: binnaDarkTheme,
            light: binnaLightTheme
        }
    },
    date: {
        adapter: DateFnsAdapter,
        locale: {
            es: esDateFns,
        }
    },
    locale: {
        locale: 'es',
        messages: {es}
    },
})

import { devtools } from '@vue/devtools'
// if (process.env.NODE_ENV === 'development') {
//     devtools.connect()
// }

createApp(App)
    .use(router)
    .use(vuetify)
    .use(pinia)
    .directive('highlightSearch', {
        updated(el: HTMLElement, binding) {
            const search = binding.value;
            const baseText = el.textContent;
            if (!baseText || !search) return;

            const regex = new RegExp(`(${search})`, 'gi');
            el.innerHTML = baseText.replace(regex, '<strong>$1</strong>');
        }
    })
    .mount('#app')